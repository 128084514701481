import React from "react";
import StepEight from "../../components/cards/onboardingCard/stepEight";
import "./style.scss";
import { LiveChatWidget } from '@livechat/widget-react'
import StepThree from "../../components/cards/onboardingCard/stepThree";

const BrandWebsite = () => {
  return (
    <div className="bg bg-img">
      <div className="mid-card card-4">
        {/* <StepEight /> */}
        <StepThree />
      </div>
      {/* <LiveChatWidget
      license="14411064"
      visibility="minimized"
    /> */}
    </div>
  );
};

export default BrandWebsite;
